<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-text-field
            class="mt-6 ml-2 mr-2"
            label="Buscar por embarque, numero de factura, HBL"
            solo
            dense
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-select class="ml-2 mr-2" label="Servico Princiapl" solo dense>
        </v-select>
      </v-col>
      <v-col cols="6">
        <v-select class="ml-2 mr-2" label="Estado" solo dense> </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="ml-2 mr-2">
        <v-data-table
            :headers="headers"
            :items="desserts"
            sort-by="calories"
            class="elevation-1"
        >
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                            v-model="editedItem.name"
                            label="Dessert name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                            v-model="editedItem.calories"
                            label="Calories"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                            v-model="editedItem.fat"
                            label="Fat (g)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                            v-model="editedItem.carbs"
                            label="Carbs (g)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                            v-model="editedItem.protein"
                            label="Protein (g)"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:item.actions>
            <v-btn class="ma-2" outlined color="indigo" to="/embarque" dense>
              Detalle
            </v-btn>
          </template>
          <template v-slot:item.ServcioPrincipal="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-ferry
            </v-icon>
          </template>
          <template v-slot:item.actions2="{ item }">
            <v-btn
                class="ma-2"
                outlined
                color="indigo"
                @click="editItem(item)"
                dense
            >
              {{ item.status }}
            </v-btn>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize"> Reset </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
name: "Embarques",
  data: () => ({
    headers: [
      { text: "Id Senator", value: "calories" },
      { text: "Referencia de cliente", value: "IDC" },
      { text: "Origen", value: "origen" },
      { text: "Destino", value: "destino" },
      { text: "Booking Nomber", value: "calories" },
      { text: "HBL", value: "calories" },
      { text: "Provedor", value: "fat" },
      { text: "Servico Principal", value: "ServcioPrincipal", sortable: false },
      { text: "Estado", value: "actions2" },
      { text: "Aciones", value: "actions", sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      origen: "Monterr",
      destino: "Dallas",
      fat: "CMA",
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.desserts = [
        {
          name: "Frozen Yogurt",
          calories: "S11527-EMFCLITA-MEX",
          fat: "CMA",
          carbs: 24,
          protein: 4.0,
          origen: "Hamburgo",
          destino: "Monterrey",
          IDC: "21929121",
          status: "Transito",
        },
        {
          name: "Ice cream sandwich",
          calories: "S11527-EMFCLITA-MEX",
          fat: "CMA",
          carbs: 37,
          protein: 4.3,
          origen: "Shanghái",
          destino: "Monterrey",
          IDC: "21929121",
          status: "Pendiente",
        },
        {
          name: "Eclair",
          calories: "S11527-EMFCLITA-MEX",
          fat: "CMA",
          carbs: 23,
          protein: 6.0,
          origen: "Hamburgo",
          destino: "Monterrey",
          IDC: "21929121",
          status: "Pendiente",
        },
        {
          name: "Cupcake",
          calories: "S11527-EMFCLITA-MEX",
          fat: "MSC",
          carbs: 67,
          protein: 4.3,
          origen: "Shanghái",
          destino: "Monterrey",
          IDC: "21929121",
          status: "Por Salir",
        },
        {
          name: "Gingerbread",
          calories: "S11527-EMFCLITA-MEX",
          fat: "MSC",
          carbs: 49,
          protein: 3.9,
          origen: "Bremerhaven",
          destino: "Monterrey",
          IDC: "21929121",
          status: "Transito",
        },
        {
          name: "Jelly bean",
          calories: "S11527-EMFCLITA-MEX",
          fat: "MSC",
          carbs: 94,
          protein: 0.0,
          origen: "Bremerhaven",
          destino: "Monterrey",
          IDC: "21929121",
          status: "Por Salir",
        },
        {
          name: "Lollipop",
          calories: "S11527-EMFCLITA-MEX",
          fat: "Hamburg Sud",
          carbs: 98,
          protein: 0,
          origen: "Roma",
          destino: "Monterrey",
          IDC: "21929121",
          status: "Transito",
        },
        {
          name: "Honeycomb",
          calories: "S11527-EMFCLITA-MEX",
          fat: "Hamburg Sud",
          carbs: 87,
          protein: 6.5,
          origen: "Bogotá",
          destino: "Monterrey",
          IDC: "21929121",
          status: "En espera",
        },
        {
          name: "Donut",
          calories: "S11527-EMFCLITA-MEX",
          fat: "Hamburg Sud",
          carbs: 51,
          protein: 4.9,
          origen: "Shanghai",
          destino: "Monterrey",
          IDC: "21929121",
          status: "En espera",
        },
        {
          name: "KitKat",
          calories: "S11527-EMFCLITA-MEX",
          fat: "Hamburg Sud",
          carbs: 65,
          protein: 7,
          origen: "Sirone",
          destino: "Monterrey",
          IDC: "21929121",
          status: "Transito",
        },
      ];
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
  },
}
</script>

<style scoped>

</style>
